
document.addEventListener("turbolinks:load", function() {
        $(".card-header-right .close-card").on('click', function() {
            var $this = $(this);
            $this.parents('.card').animate({
                'opacity': '0',
                '-webkit-transform': 'scale3d(.3, .3, .3)',
                'transform': 'scale3d(.3, .3, .3)'
            });
            setTimeout(function() {
                $this.parents('.card').remove();
            }, 800);
        });
        $(".card-header-right .reload-card").on('click', function() {
            var $this = $(this);
            $this.parents('.card').addClass("card-load");
            $this.parents('.card').append('<div class="loader-box card-loader"><div class="loader card-load"><div class="whirly-loader"></div></div></div>');
            setTimeout(function() {
                $this.parents('.card').children(".card-loader").remove();
                $this.parents('.card').removeClass("card-load");
            }, 3000);
        });
        $(".card-header-right .card-option .fa-angle-left").on('click', function() {
            var $this = $(this);
            if ($this.hasClass('fa-angle-right')) {
                $this.parents('.card-option').animate({
                    'width': '35px',
                });
            } else {
                $this.parents('.card-option').animate({
                    'width': '230px',
                });
            }
            $(this).toggleClass("fa-angle-right").fadeIn('slow');
        });
        $(".card-header-right .minimize-card").on('click', function() {
            var $this = $(this);
            var port = $($this.parents('.card'));
            var card = $(port).children('.card-body').slideToggle();
            $(this).toggleClass("fa-plus").fadeIn('slow');
        });
        $(".card-header-right .full-card").on('click', function() {
            var $this = $(this);
            var port = $($this.parents('.card'));
            port.toggleClass("full-card");
            $(this).toggleClass("icofont-resize");
        });
        $(".view-html").on('click', function() {
            var html_source = $(this).parents(".card");
            var html_chield = html_source.find(".card-body");
            html_chield.toggleClass("show-source");
            $(this).toggleClass("fa-eye");
        });
        $(".rtl .card-header-left .close-card").on('click', function() {
            var $this = $(this);
            $this.parents('.card').animate({
                'opacity': '0',
                '-webkit-transform': 'scale3d(.3, .3, .3)',
                'transform': 'scale3d(.3, .3, .3)'
            });
            setTimeout(function() {
                $this.parents('.card').remove();
            }, 800);
        });
        $(".rtl .card-header-right .card-option .fa-angle-right").on('click', function() {
            var $this = $(this);
            if ($this.hasClass('fa-angle-left')) {
                $this.parents('.card-option').animate({
                    'width': '35px',
                });
            } else {
                $this.parents('.card-option').animate({
                    'width': '230px',
                });
            }
            $(this).toggleClass("fa-angle-left").fadeIn('slow');
        });
    
});